<template>
  <svg
      :class="{'unpaid' : color === '#CF6868'}"
      width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Профиль/инструменты/тариф</title>
    <g id="Профиль/инструменты/тариф" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M25.8046875,13.7727273 C25.9125391,13.7727273 26,13.8617784 26,13.9715909 L26,13.9715909 L26,20.7727273 C26,22.0028182 25.020625,23 23.8125,23 L23.8125,23 L8.1875,23 C6.979375,23 6,22.0028182 6,20.7727273 L6,20.7727273 L6,13.9715909 C6,13.8617784 6.08746094,13.7727273 6.1953125,13.7727273 L6.1953125,13.7727273 Z M10.375,17.9090909 L9.75,17.9090909 C9.40484375,17.9090909 9.125,18.1940227 9.125,18.5454545 L9.125,18.5454545 L9.125,19.1818182 C9.125,19.53325 9.40484375,19.8181818 9.75,19.8181818 L9.75,19.8181818 L10.375,19.8181818 C10.7201563,19.8181818 11,19.53325 11,19.1818182 L11,19.1818182 L11,18.5454545 C11,18.1940227 10.7201563,17.9090909 10.375,17.9090909 L10.375,17.9090909 Z M23.8125,9 C25.020625,9 26,9.99718182 26,11.2272727 L26,11.2272727 L26,12.3011364 C26,12.4109489 25.9125391,12.5 25.8046875,12.5 L25.8046875,12.5 L6.1953125,12.5 C6.08746094,12.5 6,12.4109489 6,12.3011364 L6,12.3011364 L6,11.2272727 C6,9.99718182 6.979375,9 8.1875,9 L8.1875,9 Z" id="Combined-Shape"
            :fill="color == null ? '#788396' : color"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TariffSvg",
  props:{
    color: String
  }
}
</script>

<style scoped lang="scss">
.selected{
  g{
    path{
      fill: #333942;
    }
  }

  &:hover{
    g{
      path{
        fill: #333942;
      }
    }
  }
}

svg:hover{
  g{
    path{
      fill: #6E7889;
    }
  }
}

.unpaid:hover{
  g{
    path{
      fill: #BD6060;
    }
  }
}

.unpaid{
  g{
    path{
      fill: #CF6868;
    }
  }
}
</style>
