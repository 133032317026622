<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Профиль/инструменты/выход</title>
    <g id="Профиль/инструменты/выход" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M15.562,6.39397315 C17.5772516,6.38920813 19.54173,7.02593871 21.171,8.212 C21.3591527,8.34815517 21.4102119,8.6065059 21.288,8.804 L19.748,11.304 C19.6830951,11.4089061 19.5774711,11.4820801 19.4564477,11.5059813 C19.3354243,11.5298825 19.2099127,11.5023559 19.11,11.43 C16.794088,9.75308259 13.5861434,10.0937334 11.6740503,12.2196215 C9.76195725,14.3455096 9.76195725,17.5714904 11.6740503,19.6973785 C13.5861434,21.8232666 16.794088,22.1639174 19.11,20.487 C19.2099127,20.4146441 19.3354243,20.3871175 19.4564477,20.4110187 C19.5774711,20.4349199 19.6830951,20.5080939 19.748,20.613 L21.288,23.113 C21.4102119,23.3104941 21.3591527,23.5688448 21.171,23.705 C17.8168401,26.1299215 13.2833194,26.1215827 9.93810278,23.6843386 C6.59288618,21.2470944 5.19548384,16.9343057 6.47569954,12.9983585 C7.75591524,9.06241122 11.4230841,6.39693717 15.562,6.39397315 Z M23.303,12.1359996 C23.451781,12.1364724 23.5903891,12.2115988 23.672,12.336 L25.926,15.711 C26.0254405,15.8599262 26.0254405,16.0540738 25.926,16.203 L23.675,19.58 C23.5932634,19.7042644 23.454735,19.7793476 23.306,19.7800037 L20.306,19.7800037 C20.1407413,19.7806745 19.9888464,19.6893062 19.912,19.543 C19.8348201,19.3988915 19.8432769,19.2239901 19.934,19.088 L20.976,17.526 L14.676,17.526 C13.8094652,17.526 13.107,16.8235348 13.107,15.957 C13.107,15.0904652 13.8094652,14.388 14.676,14.388 L20.976,14.388 L19.934,12.825 C19.8431862,12.6889939 19.8346895,12.5140179 19.9118984,12.3698527 C19.9891073,12.2256876 20.1394618,12.1357856 20.303,12.1359996 L23.303,12.1359996 Z" id="Combined-Shape" fill="#CF6868"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ExitSvg"
}
</script>

<style scoped lang="scss">
  svg{
    cursor: pointer;


  }

  svg:hover{
    g{
      path{
        fill: #BD6060;
      }
    }
  }
</style>
