<template>
    <div class="navigation--bar">
        <div class="navigation--item home" @click="goToHome">
            <span class="icon-home" :class="{'active': isActive('Home')}"></span>
        </div>
        <div class="navigation--item pay" @click="goToPay">
            <img v-if="!isPaid" class="notification__img" src="../../assets/notif.svg"><span class="icon-pay" :class="{'active': isActive('Pay')}"></span>
        </div>
        <div class="navigation--item profile" @click="goToProfile">
            <span class="icon-profile" :class="{'active': isActive('Profile')}"></span>
        </div>
    </div>
</template>

<script lang>
    export default {
        name: "BottomNavigationBar",
        props:{
            activeElement: 'Home' | 'Pay' | 'Profile'
        },
        computed:{
            isPaid(){
                return this.$store.getters.PAY_STATUS
            }
        },
        methods:{

            isActive(elementName){
                return elementName === this.activeElement
            },
            async goToHome(){
                await this.$router.push({name:"ParentDashboard"})
            },

            async goToPay(){
                await this.$router.push({name:"ParentPay"})
            },

            async goToProfile(){
                await this.$router.push({name:"SettingsMainListView"})
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/mixins";


    *{
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .navigation--bar{
        display: none;
        width: $bottomToolBarWidth;
        height: $bottomToolBarHeight;
        min-height: $bottomToolBarHeight;
        background-color: rgba(255,255,255,.9);
        align-items: center;
        justify-content: space-around;
        border-top: 1px solid $bottomToolBarTopBorderColor;

        @include for-mobile-devices{
            display: flex;
        }

        .navigation--item.active{
            transform: scale(0.5);
        }

        .navigation--item{
            position: relative;
            span.active:before{
                color: $activeToolBarIconColor;
            }

            span:active, span:focus{
                outline: 0;
                background-color: transparent;
            }

            &.home{
                span{
                    font-size: 24px;
                }
            }

            &.pay{
                span{
                    font-size: 19px;
                }
            }

            &.profile{
                span{
                    font-size: 22px;
                }
            }
        }



    }


    .notification__img{
        position: absolute;
        left: 14px;
        top: -7px;
        opacity: .9;
    }


</style>
