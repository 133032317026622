<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Профиль/инструменты/фак</title>
    <g id="Профиль/инструменты/фак" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M16,6 C10.4771525,6 6,10.4771525 6,16 C6,21.5228475 10.4771525,26 16,26 C21.5228475,26 26,21.5228475 26,16 C26,13.3478351 24.9464316,10.804296 23.0710678,8.92893219 C21.195704,7.0535684 18.6521649,6 16,6 Z M16.988,20.722 L15.1,20.722 L15.1,18.87 L17.014,18.87 L16.988,20.722 Z M18.809,15.382 C18.5402573,15.7100182 18.2288266,16.0005971 17.883,16.246 L17.42,16.616 C17.1877371,16.7844626 17.0148006,17.0221627 16.926,17.295 C16.8621576,17.5367598 16.8308812,17.7859617 16.833,18.036 L15.133,18.036 C15.1302882,17.5342922 15.2031038,17.0350337 15.349,16.555 C15.552892,16.1690837 15.8498633,15.8401468 16.213,15.598 L16.676,15.228 C16.8183858,15.1253167 16.9433167,15.0003858 17.046,14.858 C17.2085032,14.6335661 17.2950445,14.3630806 17.293,14.086 C17.3007494,13.76374 17.2031438,13.4477508 17.015,13.186 C16.7641008,12.8899457 16.3812959,12.7396788 15.996,12.786 C15.5825261,12.7477067 15.1808502,12.9368657 14.947,13.28 C14.7481057,13.5828629 14.6408152,13.9366784 14.638,14.299 L12.79,14.299 C12.730282,13.2816603 13.2109759,12.3081126 14.055,11.737 C14.6106467,11.3945473 15.2546135,11.2227764 15.907,11.243 C16.7513116,11.2113584 17.5837954,11.4491609 18.284,11.922 C18.9291318,12.3936762 19.2897771,13.1613194 19.241,13.959 C19.256754,14.4669182 19.1049482,14.9659095 18.809,15.379 L18.809,15.382 Z" id="Compound_Path" fill="#788396" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FaqSvg"
}
</script>

<style scoped lang="scss">
.selected{
  g{
    path{
      fill: #333942;
    }
  }

  &:hover{
    g{
      path{
        fill: #333942;
      }
    }
  }
}

svg:hover{
  g{
    path{
      fill: #6E7889;
    }
  }
}
</style>
